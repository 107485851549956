<template>
  <div id="verify-phone">
    <invoice-header text="手机号验证"></invoice-header>

    <div class="text-box phone">
      <input type="text" v-model="phone" placeholder="请输入绑定的手机号" maxlength="20" />
    </div>

    <div class="text-box verifycode">
      <input type="text" v-model="verifycode" placeholder="请输入验证码" maxlength="10" />
      <button
        :class="{'verifycode-btn':true,'verifycode-btn--getting':verifyCodeBtn.getting}"
        @click="onGetVerifyCode"
      >{{verifyCodeBtnText}}</button>
    </div>

    <button class="confirm" @click="onConfirm">确认</button>
  </div>
</template>

<script>
import { sendVerifyCode, verifyPhone } from "./invoice";
import util from "../../commons/util";

export default {
  name: "verify-phone",
  components: {
    "invoice-header": require("./header").default
  },
  data() {
    return {
      phone: "",
      verifycode: "",
      verifyCodeBtn: {
        getting: false,
        time: 60,
        inverval: null,
      }
    };
  },
  computed: {
    verifyCodeBtnText() {
      let { getting, time } = this.verifyCodeBtn;
      return getting ? `${time}秒后重试` : "获取验证码";
    }
  },
  watch: {
    "verifyCodeBtn.time"(newVal) {
      if (newVal == 0) {
        setTimeout(() => {
          this.verifyCodeBtn.time = 60;
          this.verifyCodeBtn.getting = false;
          clearInterval(this.verifyCodeBtn.inverval);
        }, 1000);
      }
    }
  },
  methods: {
    async onGetVerifyCode() {
      const phoneCheck = /^1[3456789]\d{9}$/.test(this.phone);
      if (!phoneCheck) {
        util.toast("手机号格式不正确");
        return;
      }

      let res = await sendVerifyCode(this.phone);
      if (res.ret == "1") {
        // 按钮倒计时
        if (!this.verifyCodeBtn.getting) {
          this.verifyCodeBtn.getting = true;
        }
        this.verifyCodeBtn.inverval = setInterval(() => {
          this.verifyCodeBtn.time -= 1;
        }, 1000);
      }
      util.toast(res.message);
    },
    async onConfirm() {
      const phoneCheck = /^1[3456789]\d{9}$/.test(this.phone);
      if (phoneCheck && this.verifycode.length > 0) {
        let res = await verifyPhone(this.phone, this.verifycode);
        if (res.ret === "1") {
          setTimeout(() => {
            this.$router.push({ name: "invoice" });
          }, 1000);
        } else {
          util.toast(res.message);
        }
      }
    }
  }
};
</script>

<style lang="scss">
#verify-phone {
  .text-box {
    width: 93.333%;
    height: 0.88rem;
    overflow: hidden;
    margin: auto;
    padding-left: 0.61rem;
    border-radius: 0.06rem;
    border: solid 0.01rem #dbdbdb;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &.phone {
      margin-top: 0.73rem;
      margin-bottom: 0.4rem;
    }

    &.phone:before {
      background-image: url("./images/phone.png");
      width: 0.22rem;
      height: 0.32rem;
      margin-top: -0.16rem;
      left: 0.16rem;
    }

    &.verifycode:before {
      background-image: url("./images/message.png");
      width: 0.24rem;
      height: 0.26rem;
      margin-top: -0.13rem;
      left: 0.17rem;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 0.26rem;
    }

    .verifycode-btn {
      width: 1.74rem;
      height: 0.61rem;
      vertical-align: middle;
      background-color: #20a2dc;
      color: #ffffff;
      font-size: 0.22rem;
      cursor: pointer;
      border-radius: 0.3rem;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      margin-top: -0.305rem;
      right: 0.24rem;

      &--getting {
        background-color: #dbdbdb;
      }
    }
  }

  .confirm {
    width: 93.333%;
    height: 0.88rem;
    margin: 0 auto;
    margin-top: 0.56rem;
    background-color: #20a2dc;
    border-radius: 0.06rem;
    font-size: 0.34rem;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
</style>
